import React, { useState } from 'react'

const Testimonials = () => {

    const testimonials = [
        {
            id: 1,
            name: 'Samantha Chong',
            title: 'Highly recommended coding tuition',
            quote: 'I am so impressed with the quality of education my child is getting from this coding tuition company. Their unique way of teaching has helped my child to excel in their studies, and the problem-solving process they teach has been incredibly valuable in both their academic and personal life. I would highly recommend this company to any parent who wants a tuition service that truly cares about their child’s success',
        },
        {
            id: 2,
            name: 'Julie Thang',
            title: 'My child’s coding skills have improved dramatically',
            quote: "I am so thankful for this coding class provider for giving my child an excellent education in coding. Their teaching methods are innovative and engaging, and most importantly, effective. My child's coding skills have improved dramatically, and they have become more confident in their abilities. The team at this coding class provider is passionate about teaching coding, and they truly care about the success of their students. I would highly recommend this coding class provider to any parent looking to give their child an edge in coding",
        },
        {
            id: 3,
            name: 'Jake Tan',
            title: 'Very professional and knowledgeable',
            quote: 'I am so impressed with the quality of education my child is getting from this coding tuition center. Their unique way of teaching has helped my child to excel in their studies, and the problem-solving process they teach has been incredibly valuable in both their academic and personal life. I would highly recommend this tuition center to any parent who wants a tuition service that truly cares about their child’s success',
        },
    ]

    const [index, setIndex] = useState(0)


    const checkNumber = (number) => {
        if (number > testimonials.length - 1) {
            return 0
        }
        if (number < 0) {
            return testimonials.length - 1
        }
        return number
    }

    const nextSlide = () => {
        setIndex((index) => {
            let newIndex = index + 1
            return checkNumber(newIndex)
        })
    }

    const prevSlide = () => {
        setIndex((index) => {
            let newIndex = index - 1
            return checkNumber(newIndex)
        })
    }

    function TestomonialCard() {
        return (
            <section class="bg-gray-100 font-Itim">
                <div
                    class="mx-auto max-w-[1340px] px-4 py-16 sm:px-6 sm:py-24 lg:mr-0 lg:pl-8 lg:pr-0"
                >
                    <div
                        class="grid grid-cols-1 gap-y-8 lg:grid-cols-3 lg:items-center lg:gap-x-16"
                    >
                        <div class="max-w-xl  text-center sm:text-left">
                            <h2 class="text-3xl font-bold tracking-tight sm:text-4xl">
                                Don't just take our word for it...
                                <br class="hidden sm:block lg:hidden" />
                                Read reviews from our Parents
                            </h2>

                            <p class="mt-4 text-gray-500">
                                
                            </p>

                            <div class="hidden lg:mt-8 lg:flex lg:gap-4">
                                <button
                                    class="prev-button rounded-full border border-pink-600 p-3 text-pink-600 hover:bg-pink-600 hover:text-white"
                                    onClick={prevSlide}
                                >
                                    <span class="sr-only">Previous Slide</span>
                                    <svg
                                        class="h-5 w-5 -rotate-180 transform"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M9 5l7 7-7 7"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                        />
                                    </svg>
                                </button>

                                <button
                                    class="next-button rounded-full border border-pink-600 p-3 text-pink-600 hover:bg-pink-600 hover:text-white"
                                    onClick={nextSlide}
                                >
                                    <span class="sr-only">Next Slide</span>
                                    <svg
                                        class="h-5 w-5"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M9 5l7 7-7 7"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>

                        <div class="-mx-6 lg:col-span-2 lg:mx-0">
                            <div class="swiper-container !overflow-hidden">
                                <div class="swiper-wrapper">
                                    

                                    

                                    <div class="swiper-slide">
                                        <blockquote
                                            class="flex h-full flex-col justify-between bg-white p-12"
                                        >
                                            <div>
                                                <div class="flex gap-0.5 text-green-500">
                                                    <svg
                                                        class="h-5 w-5"
                                                        fill="currentColor"
                                                        viewBox="0 0 20 20"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                                                        />
                                                    </svg>

                                                    <svg
                                                        class="h-5 w-5"
                                                        fill="currentColor"
                                                        viewBox="0 0 20 20"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                                                        />
                                                    </svg>

                                                    <svg
                                                        class="h-5 w-5"
                                                        fill="currentColor"
                                                        viewBox="0 0 20 20"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                                                        />
                                                    </svg>

                                                    <svg
                                                        class="h-5 w-5"
                                                        fill="currentColor"
                                                        viewBox="0 0 20 20"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                                                        />
                                                    </svg>

                                                    <svg
                                                        class="h-5 w-5"
                                                        fill="currentColor"
                                                        viewBox="0 0 20 20"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                                                        />
                                                    </svg>
                                                </div>

                                                <div class="mt-4">
                                                    <p class="text-2xl font-bold text-pink-600 sm:text-3xl">
                                                    {testimonials[index].title}
                                                    </p>

                                                    <p class="mt-4 leading-relaxed text-gray-500">
                                                    {testimonials[index].quote}
                                                    </p>
                                                </div>
                                            </div>

                                            <footer class="mt-8 text-sm text-gray-500">
                                                &mdash; {testimonials[index].name}
                                            </footer>
                                        </blockquote>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mt-8 flex justify-center gap-4 lg:hidden">
                        <button
                            aria-label="Previous slide"
                            class="prev-button rounded-full border border-pink-600 p-4 text-pink-600 hover:bg-pink-600 hover:text-white"
                            onClick={prevSlide}
                        >
                            <svg
                                class="h-5 w-5 -rotate-180 transform"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M9 5l7 7-7 7"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                />
                            </svg>
                        </button>

                        <button
                            aria-label="Next slide"
                            class="next-button rounded-full border border-pink-600 p-4 text-pink-600 hover:bg-pink-600 hover:text-white"
                            onClick={nextSlide}

                        >
                            <svg
                                class="h-5 w-5"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M9 5l7 7-7 7"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </section>
        )
    }



    return (
        
        <TestomonialCard />
                
    )
}

export default Testimonials