import React from 'react'

const PageNotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen ">
        <h1 className="text-7xl text-red-500 font-bold">404</h1>
        <h2 className="text-2xl font-bold d">Page Not Found</h2>
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4">
            <a href="/">Go Home</a>
        </button>
    </div>

  )
}

export default PageNotFound