import React from 'react'

import Hero from '../Components/Hero'
import TeachingConcepts from '../Components/TeachingConcepts'
import Testimonials from '../Components/Testimonials'
import AboutUs from '../Components/AboutUs'
import Footer from '../Components/Footer'

const LandingPage = () => {
  return (
    <div>
        <Hero />
        <TeachingConcepts />
        <Testimonials />
        <AboutUs />
    </div>
  )
}

export default LandingPage