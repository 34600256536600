import React, { useState, useEffect } from "react";
import EventsData from "../data/EventsData.json";
import { Image } from "antd";
// YTAC – Youth Tech ASEAN Challenge
import ImgOne from "../Assets/YTACSG/IMG_7951.jpg";
import ImgTwo from "../Assets/YTACSG/IMG_7958.jpg";
import ImgThree from "../Assets/YTACSG/IMG_7982.jpg";
import ImgFour from "../Assets/YTACSG/IMG_8073.jpg";
import ImgFive from "../Assets/YTACSG/IMG_8206.jpg";
import ImgSix from "../Assets/YTACSG/IMG_8236.jpg";
import ImgSeven from "../Assets/YTACSG/IMG_8265.jpg";

// YTC – SEAMEO RECSAM – Specialists Educators training
import ImgEight from "../Assets/SEAMEO/Photo1.jpg";
import ImgNine from "../Assets/SEAMEO/Photo2.jpg";
import ImgTen from "../Assets/SEAMEO/Photo3.jpg";
import ImgEleven from "../Assets/SEAMEO/Photo4.jpg";
import ImgTwelve from "../Assets/SEAMEO/Photo5.jpg";
import ImgThirteen from "../Assets/SEAMEO/Photo6.jpg";

// UM UM STEM – MOU signed

import ImgTwenty from "../Assets/UMSTEM/Photo1.jpg";
import ImgTwentyOne from "../Assets/UMSTEM/Photo2.jpeg";
import ImgTwentyTwo from "../Assets/UMSTEM/Photo3.jpeg";
import ImgTwentyThree from "../Assets/UMSTEM/Photo4.jpeg";
import ImgTwentyFour from "../Assets/UMSTEM/Photo5.jpeg";
import ImgTwentyFive from "../Assets/UMSTEM/Photo6.jpeg";
import ImgTwentySix from "../Assets/UMSTEM/Photo7.jpeg";

// SEAMEO RECSAM - MOU signed as collaborative partner

import ImgEighteen from "../Assets/SEAMEORECSAM/Photo1.jpg";
import ImgNineteen from "../Assets/SEAMEORECSAM/Photo2.jpg";

// Playbookx Sdn. Bhd. – MOU signed as strategy partner
import ImgFourteen from "../Assets/Playbook/Photo1.jpg";
import ImgFifteen from "../Assets/Playbook/Photo2.jpg";
import ImgSixteen from "../Assets/Playbook/Photo3.jpg";
import ImgSeventeen from "../Assets/Playbook/Photo4.jpg";

//YTAC

const EventDetails = () => {
  const [event, setEvent] = useState(EventsData);
  const [visible, setVisible] = useState(false);

  const [loading, setLoading] = useState(false);
  const id = window.location.pathname.split("/")[2];

  const FetchEvent = async () => {
    setLoading(true);
    const res = await fetch("src/data/EventsData.json");
    const data = await res.json();
    setEvent(data);
    setLoading(false);
  };

  const FilteredEvent = event.filter((event) => event.id === id);

  return (
    <div className="flex flex-col w-full py-4 px-6">
      <div className="grid grid-cols-2 gap-4 md:grid-cols-8 ">
        <div className="col-span-2 ">
          <div className="flex font-Itim text-6xl flex-col justify-start px-4 py-2 m-4 bg-red-500 rounded-lg shadow-lg">
            {FilteredEvent.map((event) => (
              <div key={event.id}>
                <h1 className="text-4xl font-bold text-white">{event.title}</h1>
              </div>
            ))}
          </div>
          <div className="flex font-Itim text-6xl flex-col justify-start px-4 py-2 m-4 bg-yellow-700 rounded-lg shadow-lg">
            {FilteredEvent.map((event) => (
              <div key={event.id}>
                <h1 className="text-lg font-bold text-white">
                  {event.description}
                </h1>
              </div>
            ))}
          </div>
        </div>

        <div className="col-span-6 ">
          <div className="flex flex-col justify-start p-2 w-full mx-auto m-2 ">
            {FilteredEvent.map((event) => (
              <div key={event.id}>
                {event.id === "1" && (
                  <>
                    <Image
                      preview={{
                        visible: false,
                      }}
                      className="rounded-lg shadow-lg"
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                      src={ImgSix}
                      onClick={() => setVisible(true)}
                    />
                    <div
                      style={{
                        display: "none",
                      }}
                    >
                      <Image.PreviewGroup
                        preview={{
                          visible,
                          onVisibleChange: (vis) => setVisible(vis),
                        }}
                      >
                        <Image src={ImgOne} />
                        <Image src={ImgTwo} />
                        <Image src={ImgThree} />
                        <Image src={ImgFour} />

                        <Image src={ImgFive} />
                        <Image src={ImgSix} />
                        <Image src={ImgSeven} />
                      </Image.PreviewGroup>
                    </div>
                  </>
                )}

                {event.id === "2" && (
                  <>
                    <Image
                      preview={{
                        visible: false,
                      }}
                      className="rounded-lg shadow-lg"
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                      src={ImgEight}
                      onClick={() => setVisible(true)}
                    />
                    <div
                      style={{
                        display: "none",
                      }}
                    >
                      <Image.PreviewGroup
                        preview={{
                          visible,
                          onVisibleChange: (vis) => setVisible(vis),
                        }}
                      >
                        <Image src={ImgEight} />
                        <Image src={ImgNine} />
                        <Image src={ImgTen} />
                        <Image src={ImgEleven} />
                        <Image src={ImgTwelve} />
                        <Image src={ImgThirteen} />
                      </Image.PreviewGroup>
                    </div>
                  </>
                )}

                {event.id === "3" && (
                  <>
                    <Image
                      preview={{
                        visible: false,
                      }}
                      className="rounded-lg shadow-lg"
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                      src={ImgTwenty}
                      onClick={() => setVisible(true)}
                    />
                    <div
                      style={{
                        display: "none",
                      }}
                    >
                      <Image.PreviewGroup
                        preview={{
                          visible,
                          onVisibleChange: (vis) => setVisible(vis),
                        }}
                      >
                        <Image src={ImgTwenty} />
                        <Image src={ImgTwentyOne} />
                        <Image src={ImgTwentyTwo} />
                        <Image src={ImgTwentyThree} />
                        <Image src={ImgTwentyFour} />
                        <Image src={ImgTwentyFive} />
                        <Image src={ImgTwentySix} />
                      </Image.PreviewGroup>
                    </div>
                  </>
                )}

                {event.id === "5" && (
                  <>
                    <Image
                      preview={{
                        visible: false,
                      }}
                      className="rounded-lg shadow-lg"
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                      src={ImgFourteen}
                      onClick={() => setVisible(true)}
                    />
                    <div
                      style={{
                        display: "none",
                      }}
                    >
                      <Image.PreviewGroup
                        preview={{
                          visible,
                          onVisibleChange: (vis) => setVisible(vis),
                        }}
                      >
                        <Image src={ImgFourteen} />
                        <Image src={ImgFifteen} />
                        <Image src={ImgSixteen} />
                        <Image src={ImgSeventeen} />
                      </Image.PreviewGroup>
                    </div>
                  </>
                )}

                {event.id === "4" && (
                  <>
                    <Image
                      preview={{
                        visible: false,
                      }}
                      className="rounded-lg shadow-lg"
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                      src={ImgEighteen}
                      onClick={() => setVisible(true)}
                    />
                    <div
                      style={{
                        display: "none",
                      }}
                    >
                      <Image.PreviewGroup
                        preview={{
                          visible,
                          onVisibleChange: (vis) => setVisible(vis),
                        }}
                      >
                        <Image src={ImgEighteen} />
                        <Image src={ImgNineteen} />
                      </Image.PreviewGroup>
                    </div>
                  </>
                )}

                {event.id === "6" && (
                  <>
                    <Image
                      preview={{
                        visible: false,
                      }}
                      className="rounded-lg shadow-lg"
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                      src={ImgEighteen}
                      onClick={() => setVisible(true)}
                    />
                    <div
                      style={{
                        display: "none",
                      }}
                    >
                      <Image.PreviewGroup
                        preview={{
                          visible,
                          onVisibleChange: (vis) => setVisible(vis),
                        }}
                      >
                        <Image src={ImgEighteen} />
                        <Image src={ImgNineteen} />
                      </Image.PreviewGroup>
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventDetails;
