import logo from './logo.svg';
import './App.css';

import { Route, Routes } from 'react-router-dom';

// Navbar
import Layout from './Layout/Layout';

// Pages
import LandingPage from './Pages/LandingPage';
import Events from './Pages/Events';
import EventDetails from './Pages/EventDetails';
import Partnerships from './Pages/Partnerships';
import CoursesDetails from './Pages/CoursesDetails';
import YTAC from './Pages/YTAC';

// Page Not Found 
import PageNotFound from './Components/PageNotFound';

function App() {
  return (
    <>
    <div className="App">
      <Routes>
        <Route path="/" element={<Layout />} >
          <Route path="/" element={<LandingPage />} />
          <Route path="*" element={<PageNotFound />} />
          <Route path="/ytac" element = {<YTAC/>}/>
          <Route path="/events" element={<Events />} />
          <Route path="/events/:id" element={<EventDetails />} />

          <Route path="/partnerships" element={<Partnerships />} />
          <Route path="/courses/:id" element={<CoursesDetails />} />
        </Route>

      </Routes>
    </div>
    </>
  );
}

export default App;
