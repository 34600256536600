import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import CoursesData from '../data/CoursesData.json'



const CoursesDetails = () => {
    const { id } = useParams()
    const [course, setCourse] = useState(CoursesData.find((course) => course.id === id))

    
    useEffect(() => {
        setCourse(CoursesData.find((course) => course.id === id))
    }, [id])





    
    return (
        <div className="flex flex-col md:p-4 items-start justify-center space-y-4 ">
            <div className="flex flex-col md:mx-20 mx-2 m-auto p-4 mb-2 gap-3 items-start justify-start">
                <div className="flex flex-col p-4 mb-2 gap-3 items-start justify-start ">
                    <h1 className="text-4xl font-bold text-gray-800">{course.title}</h1>
                    <p className="text-xl  text-gray-500">{course.description}</p>
                </div>
                <div className="flex flex-col items-start justify-center space-y-4  bg-red-50">
                    {/* <video src={course.video}  className="bg-gray-200 sm:h-[10vh] w-[90vw] md:h-[50vh] object-cover rounded-md" /> */}
                    <video
                        className="bg-gray-200 sm:h-[10vh] w-[90vw] md:h-[90vh] object-cover rounded-md"
                        controls
                        src={course.video}
                        type="video/mp4"
                    
                    />

                </div>
                {/* <div className="flex flex-col p-4 mb-2 gap-3  bg-red-50  items-start justify-center ">
                <h1 className="text-4xl font-bold text-gray-800">About the Course</h1>
                <p className="text-xl  text-gray-500">{course.description}</p>
            </div> */}
                <div className="flex flex-col p-4 mb-2 gap-3  items-start justify-center ">
                    <h1 className="text-4xl font-bold text-gray-800">After completing this course, you will be able to:</h1>
                    <ul className="text-xl  text-gray-500">
                        {course?.benefits?.map((benefit) => (
                            <li className="flex flex-row gap-2 items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                </svg>
                                <p>{benefit}</p>
                            </li>

                        ))}
                        {course?.benefits === undefined && <p>loading...</p>}
                        {course?.benefits?.length === 0 && <p>No Data Found</p>}
                    </ul>
                </div>

                <button className="flex flex-row gap-2 items-center w-full justify-center px-4 py-2 text-sm font-medium text-black border rounded-md  focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 bg-yellow-400 hover:bg-yellow-300"
                onClick={() => window.open(`https://api.whatsapp.com/send?phone=60146286369&text=Hi there! Could you tell me more about the ${course.title} course you offer? I'm interested in enrolling my kid. Thank you!`, '_blank')}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                    </svg>
                    <p>
                        Enroll Now
                    </p>
                </button>
            </div>




        </div>

    )
}

export default CoursesDetails