import React from 'react'
import Research from  '../Assets/research.png'
import Problemdefinition from  '../Assets/Problemdefinition.png'
import digitaliseconcept from  '../Assets/digitaliseconcept.png'
import brainstorm from  '../Assets/brainstorm.png'
import projectplaning from  '../Assets/projectplaning.png'

const TeachingConcepts = () => {
  return (
    <div className='flex flex-col items-center justify-center  ' id='teaching-concepts'>
        <div className='px-4 py-16 mx-auto sm:max-w-full  md:max-w-full lg:max-w-screen-full md:px-24 lg:px-8 lg:py-20'>
            <div className='flex flex-col items-center justify-center max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12'>
                <h2 className='max-w-lg mb-6 text-3xl font-Itim font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:text-5xl'>
                Our Course Concept
                </h2>
                <p className='text-base text-gray-700 font-Itim md:text-5xl'>
                    How we teach our students
                </p>
            </div>
            <div className='grid font-Itim gap-8 row-gap-5 sm:row-gap-8 sm:grid-cols-1 lg:grid-cols-5'>
                <div className='flex flex-col justify-between p-5 transition duration-300 bg-white border border-red-100 rounded shadow-sm hover:shadow-lg  hover:bg-red-300'>
                    <div className='flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-red-50'>
                        <img src={Problemdefinition} alt='Research' className='w-8 h-8' />
                    </div>
                    <p className='mb-3 text-sm font-medium tracking-wide text-gray-900' >
                        <a href='#' aria-label='Learn more' title='Learn more'>
                            Problem Definition
                        </a>
                    </p>
                    <p className='text-sm text-gray-700 '>
                    The first step in our problem-solving process is to identify the issue that needs to be solved. Through research and analysis, we examine the problem and explore what others have done to address it.                    </p>
                </div>
                <div className='flex flex-col justify-between p-5 transition duration-300 bg-white border border-yellow-100 rounded shadow-sm hover:shadow-lg  hover:bg-yellow-300'>
                    <div className='flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-yellow-50'>
                    <img src={Research} alt='Research' className='w-8 h-8' />
                    </div>
                    <p className='mb-3 text-sm font-medium tracking-wide text-gray-900 '>
                        <a href='#' aria-label='Learn more' title='Learn more'>
                            Research
                        </a>
                    </p>
                    <p className='text-sm text-gray-700 '>
                    Once the problem has been identified, we conduct research to gather relevant information and analyze the available data. This helps us to understand the problem in-depth and generate ideas for potential solutions                    </p>
                </div>

                <div className='flex flex-col justify-between p-5 transition duration-300 bg-white border border-lime-100 rounded shadow-sm hover:shadow-lg  hover:bg-lime-300'>
                    <div className='flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-lime-50'>
                    <img src={brainstorm} alt='Research' className='w-8 h-8' />
                    </div>
                    <p className='mb-3 text-sm font-medium tracking-wide text-gray-900 '>
                        <a href='#' aria-label='Learn more' title='Learn more'>
                            Brain Storming
                        </a>
                    </p>
                    <p className='text-sm text-gray-700 '>
                    Next, we facilitate a brainstorming session where students generate as many ideas as possible, without any judgment or evaluation. This encourages students to think creatively and consider innovative solutions                    </p>
                </div>

                <div className='flex flex-col justify-between p-5 transition duration-300 bg-white border border-teal-100 rounded shadow-sm hover:shadow-lg  hover:bg-teal-300'>
                    <div className='flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-teal-50'>
                    <img src={projectplaning} alt='Research' className='w-8 h-8' />
                    </div>
                    <p className='mb-3 text-sm font-medium tracking-wide text-gray-900 '>
                        <a href='#' aria-label='Learn more' title='Learn more'>
                            Project Planning
                        </a>
                    </p>
                    <p className='text-sm text-gray-700 '>
                    Once the solution has been chosen, we guide students in planning the project, determining what needs to be done, how it will be accomplished, and the timeline for completion.
                    </p>
                                    </div>

                <div className='flex flex-col justify-between p-5 transition duration-300 bg-white border border-purple-100 rounded shadow-sm hover:shadow-lg hover:bg-purple-300'>
                    <div className='flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-purple-50'>
                    <img src={digitaliseconcept} alt='Research' className='w-8 h-8' />
                    </div>
                    <p className='mb-3 text-sm font-medium tracking-wide text-gray-900 '>
                        <a href='#' aria-label='Learn more' title='Learn more'>
                            Digitalise the concept Ideas
                        </a>
                    </p>
                    <p className='text-sm text-gray-700 '>
                    Finally, we use cutting-edge tools to bring the chosen solution to life, creating a digital concept that can be refined and improved upon.                    </p>
                </div>
            </div>
        </div>




        
    </div>
  )
}

export default TeachingConcepts



{/* <div className='max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12'>
                <h2 className='max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none'>
                    Teaching Concepts
                </h2>
                <p className='text-base text-gray-700 md:text-lg'>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.
                </p>
            </div>
            <div className='grid gap-8 row-gap-5 sm:row-gap-8 sm:grid-cols-2 lg:grid-cols-4'>
                <div className='flex flex-col justify-between p-5 transition duration-300 bg-white rounded shadow-sm hover:shadow-lg'>
                    <div className='flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-indigo-50'>
                        <svg className='w-8 h-8 text-deep-purple-accent-400' viewBox='0 0 24 24'>
                            <path
                                fill='currentColor'
                                d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 15h-2v-6h2v6zm0-8h-2V7h2v2z'
                            />
                        </svg>
                    </div>
                    <p className='mb-3 text-sm font-medium tracking-wide text-gray-900'>
                        <a href='#' aria-label='Learn more' title='Learn more'>
                            Learn to code
                        </a>
                    </p>
                    <p className='text-sm text-gray-700'>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </p>
                </div>
                <div className='flex flex-col justify-between p-5 transition duration-300 bg-white rounded shadow-sm hover:shadow-lg'>
                    <div className='flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-indigo-50'>
                        <svg className='w-8 h-8 text-deep-purple-accent-400' viewBox='0 0 24 24'>
                            <path fill='currentColor' d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 15h-2v-6h2v6zm0-8h-2V7h2v2z' />
                        </svg>
                    </div>
                    <p className='mb-3 text-sm font-medium tracking-wide text-gray-900'>
                        <a href='#' aria-label='Learn more' title='Learn more'>
                            Learn to code
                        </a>
                    </p>
                    <p className='text-sm text-gray-700'>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </p>
                </div>
            </div>
        </div> */}