import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import EventsData from "../data/Ytac.json";
import { Image } from "antd";
import Letter from "../Assets/YTAC/letter.png";
import Events from "../Assets/YTAC/events.jpg";
import Certificate from "../Assets/YTAC/certificate.png";
import Logo from "../Assets/YTAC/logo.png";
import Banner from "../Assets/YTAC/banner.jpg";
import Carousell1 from "../Assets/YTAC/1.jpg";

const YTAC = () => {
  // const [events, setEvents] = useState(EventsData);
  const [shorternedDescription, setShorternedDescription] = useState("");

  const [loading, setLoading] = useState(false);

  // const FetchEvents = async () => {
  //   setLoading(true);
  //   const res = await fetch("src/data/Ytac.json");
  //   const data = await res.json();
  //   setEvents(data);
  //   setLoading(false);
  // };

  const ReadMore = (description) => {
    if (description.length > 180) {
      return description.substring(0, 100) + " Read More...";
    } else {
      return description;
    }
  };

  function EventsSection() {
    return (
      <div className="flex flex-col items-center justify-center p-4 m-4">
        <div className="max-w-screen-md w-full">
          <div className="flex flex-col items-center justify-center">
            <img className="h-24 mx-auto" src={Logo} alt="logo" />
            <h1 className="text-4xl py-10 mx-30  font-extrabold tracking-tight text-gray-900 sm:text-5xl text-center">
              Youth Tech Asia Challenge (YTAC)
            </h1>
            <div className="flex justify-center mb-12 ">
              <a
                href="https://forms.gle/AuXMNSf7VNu52Fqu7"
                className=" flex items-center w-64 justify-center px-8 py-3 text-base font-medium text-white  border border-transparent rounded-md hover:bg-indigo-700 md:py-4 md:text-lg md:px-10 bg-gradient-to-r from-red-500 to-orange-500  hover:from-pink-500 hover:to-yellow-500"
                target="_blank"
              >
                Register Now
              </a>
              <a
                href="https://drive.google.com/drive/folders/1a8L7WWVQwXW4Hrx_1Amm789hNcm91woC"
                className="mx-12 flex items-center w-64 justify-center px-8 py-3 text-base font-medium text-white  border border-transparent rounded-md hover:bg-indigo-700 md:py-4 md:text-lg md:px-10 bg-gradient-to-r from-red-500 to-orange-500  hover:from-pink-500 hover:to-yellow-500"
                target="_blank"
              >
                Handbook
              </a>
              <a
                href="https://drive.google.com/drive/folders/1rcoWwsqCE1X4JXt9ta1P2QYGN0FTLF4Y"
                className=" flex items-center w-64 justify-center px-8 py-3 text-base font-medium text-white  border border-transparent rounded-md hover:bg-indigo-700 md:py-4 md:text-lg md:px-10 bg-gradient-to-r from-red-500 to-orange-500  hover:from-pink-500 hover:to-yellow-500"
                target="_blank"
              >
                Rules & Regulations
              </a>
            </div>

            <div>
              <img src={Banner} alt="banner" />
            </div>

            {/* */}

            <hr class="h-px my-8 bg-gray-200 border-0 "></hr>

            {/* */}

            <div className="m-4 md:m-8 bg-white shadow-md rounded-lg overflow-hidden flex flex-col md:flex-row items-center justify-center bg-gradient-to-r from-orange-50 to-cyan-50">
              <div className="w-full md:w-8/12 overflow-auto  mx-2  ">
                <Image
                  src={Events}
                  alt="Card"
                  className="object-cover h-full w-full mb-0"
                />
              </div>
              <div className="w-full md:w-4/12 p-4 overflow-auto md:border-l-2">
                <div class="px-6 py-4  border-t-2 md:border-t-0">
                  <div class="font-bold text-2xl md:text-4xl">YTAC Events</div>
                  <div class="mt-4 max-h-[400px] overflow-auto">
                    <div class="mr-2">
                      <p class="text-gray-700 text-base">
                        The image showcases a diverse array of domestic and
                        international events organized by YTAC (Youth Tech Asia
                        Challenge). It encapsulates the vibrant and dynamic
                        nature of YTAC's event calendar, which encompasses a
                        wide range of competitions, performances, and
                        activities. From local talent showcases to global
                        collaborations, the image captures the essence of YTAC's
                        commitment to providing opportunities for youth to
                        showcase their talents and achievements on both domestic
                        and international stages.
                      </p>
                      <br></br>
                    </div>
                  </div>
                </div>
                <div class="px-6 pt-4 pb-2 ">
                  <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                    #Competition
                  </span>
                  <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                    #Events
                  </span>
                  <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                    #International
                  </span>
                  <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                    #Domestic
                  </span>
                  <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                    #YTAC
                  </span>
                </div>
              </div>
            </div>
            {/* */}
            <div className="m-4 md:m-8 bg-white shadow-md rounded-lg overflow-hidden flex flex-col md:flex-row items-center justify-center bg-gradient-to-r from-orange-50 to-cyan-50">
              <div className="w-full md:w-1/3 overflow-auto  mx-2  ">
                <Image
                  src={Letter}
                  alt="Card"
                  className="object-cover h-full w-full mb-0"
                />
              </div>
              <div className="w-full md:w-2/3 p-4 overflow-auto md:border-l-2">
                <div class="px-6 py-4  border-t-2 md:border-t-0">
                  <div class="font-bold text-2xl md:text-4xl">
                    BSKK Approval Letter
                  </div>
                  <div class="mt-4 max-h-[400px] overflow-auto">
                    <div class="mr-2">
                      <p class="text-gray-700 text-base">
                        The image showcases an official document known as the
                        BSKK Approval Letter. This letter serves as a
                        significant government approval and endorsement for our
                        YTAC (Youth Tech Asia Challenge) event.
                      </p>
                      <br></br>

                      <p class="text-gray-700 text-base">
                        The BSKK Approval Letter represents the recognition and
                        support of the government towards our event, which
                        provides a platform for students to showcase their
                        talents and achievements. It signifies the importance of
                        promoting physical education, sports, and co-curricular
                        activities among the youth.
                      </p>
                      <br></br>

                      <p class="text-gray-700 text-base">
                        During the YTAC event, students have the opportunity to
                        participate and compete in various activities and
                        performances. These activities are not only an avenue
                        for students to showcase their skills but also an
                        opportunity to earn scores based on the PAJSK
                        (Pentaksiran Aktiviti Jasmani Sukan & Kokurikulum)
                        scoring system.
                      </p>
                      <br></br>

                      <p class="text-gray-700 text-base">
                        Overall, the image of the BSKK Approval Letter
                        represents the official government endorsement and
                        validation of our YTAC event, which offers students the
                        opportunity to earn scores from the PAJSK scoring
                        system, encouraging their active participation and
                        recognition in physical education and co-curricular
                        activities.
                      </p>
                      <br></br>
                    </div>
                  </div>
                </div>
                <div class="px-6 pt-4 pb-2 ">
                  <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                    #GovernmentApproved
                  </span>
                  <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                    #ScoringSystem
                  </span>
                  <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                    #YTAC
                  </span>
                </div>
              </div>
            </div>

            {/* */}
            <div className="m-4 md:m-8 bg-white shadow-md rounded-lg overflow-hidden flex flex-col md:flex-row items-center justify-center bg-gradient-to-r from-orange-50 to-cyan-50">
              <div className="w-full md:w-1/3 overflow-auto mx-2 ">
                <Image
                  src={Certificate}
                  alt="Card"
                  className="object-cover h-full w-full mb-0"
                />
              </div>
              <div className="w-full md:w-2/3 p-4 overflow-auto md:border-l-2">
                <div class="px-6 py-4  border-t-2 md:border-t-0">
                  <div class="font-bold text-2xl md:text-4xl">
                    YTAC Certificate
                  </div>
                  <div class="mt-4 max-h-[400px] overflow-auto">
                    <div class="mr-2">
                      <p class="text-gray-700 text-base">
                        The image displays a competition certificate awarded by
                        YTAC (Youth Tech Asia Challenge) to deserving
                        participants. This certificate holds significant
                        recognition as it is endorsed by renowned partners,
                        including the prestigious University of Malaya (UM) - a
                        top-ranking university in the world, UMSTEM (UM Science,
                        Technology, Engineering, and Mathematics Society), and
                        other esteemed organizations.
                      </p>
                      <br></br>

                      <p class="text-gray-700 text-base">
                        The competition certificate signifies the exceptional
                        achievements and talents of the recipients in their
                        respective fields. It serves as a testament to their
                        dedication, hard work, and outstanding performance in
                        the YTAC event.
                      </p>
                      <br></br>

                      <p class="text-gray-700 text-base">
                        The recognition and endorsement by University of Malaya,
                        known for its academic excellence and global reputation,
                        add immense value and credibility to the certificate. It
                        establishes a strong connection between the
                        participants' accomplishments and the renowned
                        institution, enhancing the recipients' profiles and
                        future opportunities. Moreover, the association with
                        UMSTEM further highlights the significance of the
                        certificate. UMSTEM is a prominent society within the
                        university that focuses on promoting excellence in the
                        fields of Science, Technology, Engineering, and
                        Mathematics. Their partnership with YTAC underscores the
                        recognition of participants' talents and achievements in
                        these areas.
                      </p>
                      <br></br>
                    </div>
                  </div>
                </div>
                <div class="px-6 pt-4 pb-2 ">
                  <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                    #Certificate
                  </span>
                  <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                    #Competition
                  </span>
                  <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                    #Achievement
                  </span>
                  <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                    #YTAC
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col items-center justify-center p-4 m-4">
        <EventsSection />
      </div>
    </>
  );
};

export default YTAC;
