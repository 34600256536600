import React, { useState } from 'react'
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
    ArrowPathIcon,
    Bars3Icon,
    BookmarkSquareIcon,
    CalendarIcon,
    ChartBarIcon,
    CursorArrowRaysIcon,
    LifebuoyIcon,
    PhoneIcon,
    PlayIcon,
    ShieldCheckIcon,
    Squares2X2Icon,
    XMarkIcon,
    BoltIcon,
    ArchiveBoxIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Link, Outlet } from 'react-router-dom'
import Logo from '../Assets/Logo1.png'
import CoursesData from '../data/CoursesData.json'



import Hero from '../Components/Hero'
import TeachingConcepts from '../Components/TeachingConcepts'
import Testimonials from '../Components/Testimonials'
import AboutUs from '../Components/AboutUs'
import Footer from '../Components/Footer'





function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


const navigation = [
    { name: 'Home', href: '/', current: false },
    { name: 'Events', href: '/events', current: true },
    { name: 'Partnerships', href: '/partnerships', current: false },
    { name: 'YTAC', href: '/ytac', current: false },
]



const Layout = () => {

    const [courses, setCourses] = useState(CoursesData)


    const FetchCourses = async () => {
        const res = await fetch('src/data/CoursesData.json')
        const data = await res.json()
        setCourses(data)
        console.log(data)
    }

    return (
        <Popover className="relative bg-white darks:bg-inherit h-screen darks:bg-gray-900 ">
            <div className="mx-auto max-w-full px-4  sm:px-6 darks:bg-gray-900">
                <div className="flex items-center justify-between border-b-2 border-gray-100 darks:border-gray-600 py-6 md:justify-start md:space-x-10">
                    <div className="flex justify-start lg:w-0 lg:flex-1">
                        <a href="/" className="min-w-full ">
                            <img className="h-10 w-32 sm:h-10" src={Logo} alt="Logo" />
                        </a>
                    </div>
                    <div className="-my-2 -mr-2 md:hidden">
                        <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400  darks:bg-gray-900 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                            <span className="sr-only">Open menu</span>
                            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                        </Popover.Button>
                    </div>

                    <div className="hidden lg:flex lg:gap-x-12">
                        {navigation.map((item) => (
                            <a key={item.name} href={item.href} className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900 ml-2 darks:hover:text-gray-100 ">
                                {item.name}
                            </a>
                        ))}
                    </div>

                    <Popover.Group as="nav" className="hidden md:flex space-x-10">
                        <Popover className="relative">
                            {({ open }) => (
                                <>
                                    <Popover.Button
                                        className={classNames(
                                            open ? 'text-gray-900 darks:text-gray-100' : 'text-gray-500 darks:text-gray-400 hover:text-500 darks:hover:text-gray-100',
                                            'group bg-white darks:bg-gray-900 rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 darks:hover:text-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500'
                                        )}
                                    >
                                        <span>Courses</span>
                                        <ChevronDownIcon
                                            className={classNames(
                                                open ? 'text-gray-600' : 'text-gray-400',
                                                'ml-2 h-5 w-5 group-hover:text-gray-500'
                                            )}
                                            aria-hidden="true"
                                        />
                                    </Popover.Button>

                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-200"
                                        enterFrom="opacity-0 translate-y-1"
                                        enterTo="opacity-100 translate-y-0"
                                        leave="transition ease-in duration-150"
                                        leaveFrom="opacity-100 translate-y-0"
                                        leaveTo="opacity-0 translate-y-1"
                                    >
                                        <Popover.Panel className="absolute z-10 w-screen max-w-md px-4 mt-3 transform -translate-x-1/2 left-1/2 sm:px-0 lg:left-8 lg:ml-0  lg:max-w-3xl">
                                            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                                                <div className="relative grid gap-6 bg-white darks:bg-gray-900 px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2">
                                                    {courses.map((item) => (
                                                        <Link to={`courses/${item.id}`} key={item.id} className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 darks:hover:bg-gray-800">
                                                            <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md  text-white sm:h-12 sm:w-12">
                                                            <img src={item.image} className='h-10 w-10 rounded-md' alt={item.title} />
                                                            </div>
                                                            <div className="ml-4">
                                                                <p className="text-base font-medium text-gray-900 darks:text-gray-100">{item.title}</p>
                                                                <p className="mt-1 text-sm text-gray-500 darks:text-gray-400">{item.description}</p>
                                                            </div>
                                                        </Link>
                                                    ))}
                                                </div>
                                                {/* <div className="p-5 bg-gray-50 darks:bg-gray-800 sm:p-8">
                                                    <a href="#" className="-m-3 p-3 flow-root rounded-md hover:bg-gray-100 darks:hover:bg-gray-700">
                                                        <div className="flex items-center">
                                                            <div className="text-base font-medium text-gray-900 darks:text-gray-100">Enterprise</div>
                                                            <span className="ml-3 inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-blue-100 text-blue-800">
                                                                New
                                                            </span>
                                                        </div>
                                                        <p className="mt-1 text-sm text-gray-500 darks:text-gray-400">
                                                            Empower your entire team with even more advanced tools.
                                                        </p>
                                                    </a>
                                                </div> */}
                                            </div>
                                        </Popover.Panel>
                                    </Transition>
                                </>
                            )}
                        </Popover>
                    </Popover.Group>
                    <div className="hidden items-center justify-end md:flex md:flex-1 lg:w-0">
                        <Link
                            to='https://wa.link/xxm4x6'
                            className="ml-8 inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-pink-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-pink-700"
                            target="_blank"
                        >
                            Contact Us
                        </Link>
                    </div>
                </div>
            </div>
            <main className="mx-auto max-w-full ">
                <Outlet />
                <div className="mt-20">
                <Footer  />
                </div>
            </main>
            <Transition
                as={Fragment}
                enter="duration-200 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                <Popover.Panel focus className="absolute inset-x-0 top-0 origin-top-right transform p-2 transition md:hidden ">
                    <div className="divide-y-2 divide-gray-50  rounded-lg bg-white shadow-lg ring-2 ring-black ring-opacity-5   divide-opacity-50 ">
                        <div className="px-5 pt-5 pb-6 ">
                            <div className="flex items-center justify-between">
                                <div>
                                    <img
                                        className="h-8 w-auto"
                                        src={Logo}
                                        alt="Your Company"
                                    />
                                </div>
                                <div className="-mr-2">
                                    <Popover.Button className="inline-flex items-center justify-center rounded-md  p-2 text-gray-400 hover:bg-gray-100 darks:bg-gray-900 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500 ">
                                        <span className="sr-only">Close menu</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </Popover.Button>
                                </div>
                            </div>
                            <div className="mt-6 ">
                                <nav className="grid gap-y-8 ">
                                    {navigation.map((item) => (
                                        <>
                                            <a key={item.name} href={item.href} className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900 ml-2  ">
                                                {item.name}
                                            </a>

                                        </>
                                    ))}
                                    {courses.map((item) => (
                                        <Link to={`courses/${item.id}`} key={item.id} 
                                         className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 darks:hover:bg-gray-800">
                                            <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md text-white sm:h-12 sm:w-12">
                                                <img src={item.image} className='h-10 w-10  rounded-md' alt='logo' />
                                            </div>
                                            <div className="ml-4">
                                                <p className="text-base font-medium text-gray-900 darks:text-gray-100">{item.title}</p>
                                                <p className="mt-1 text-sm text-gray-500 darks:text-gray-400">{item.description}</p>
                                            </div>
                                        </Link>
                                    ))}
                                </nav>
                            </div>
                        </div>
                        <div className="space-y-6 py-6 px-5 ">
                            <div>
                                <Link
                                    to='https://wa.link/xxm4x6'
                                    className="flex w-full items-center justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700"
                                >
                                    Contact Us
                                </Link>
                            </div>
                        </div>
                    </div>

                </Popover.Panel>
            </Transition>
        </Popover>
    )
}

export default Layout