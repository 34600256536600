import { ArrowPathIcon, CloudArrowUpIcon, FingerPrintIcon, LockClosedIcon } from '@heroicons/react/24/outline'

import UMSLogo from '../Assets/umlogo.jpg'
import PlayBookxLogo from '../Assets/playbookx.png'

const Partnerships = () => {

  const features = [
    {
      name: 'Push to deploy',
      description:
        'Morbi viverra dui mi arcu sed. Tellus semper adipiscing suspendisse semper morbi. Odio urna massa nunc massa.',
      icon: CloudArrowUpIcon,
    },
    {
      name: 'SSL certificates',
      description:
        'Sit quis amet rutrum tellus ullamcorper ultricies libero dolor eget. Sem sodales gravida quam turpis enim lacus amet.',
      icon: LockClosedIcon,
    },
    {
      name: 'Simple queues',
      description:
        'Quisque est vel vulputate cursus. Risus proin diam nunc commodo. Lobortis auctor congue commodo diam neque.',
      icon: ArrowPathIcon,
    },
    {
      name: 'Advanced security',
      description:
        'Arcu egestas dolor vel iaculis in ipsum mauris. Tincidunt mattis aliquet hac quis. Id hac maecenas ac donec pharetra eget.',
      icon: FingerPrintIcon,
    },
  ]

  const PartnershipsData = [
    {
      id: 1,
      title: 'Universal Creative Kidz',
      description: 'Universal Creative Kidz is a non-profit organization that aims to provide a platform for children to learn and develop their creative skills. We believe that every child is born with a creative mind and we want to help them unleash their creativity. We are currently working with the University of Malaya to develop a creative learning platform for children.',
      image: 'https://images.unsplash.com/photo-1572314997669-275cf96124fc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=701&q=80',
      location: 'Puchong',
      tel: '016-639 0931',
      address: '24-1, 24-1, Jalan BK 5a/3b, Bandar Kinrara, 47810 Puchong, Selangor',
    },
    {
      id: 2,
      title: 'STREAM Academy',
      description: 'STREAM Academy is a non-profit organization that aims to provide a platform for children to learn and develop their creative skills. We believe that every child is born with a creative mind and we want to help them unleash their creativity. We are currently working with the University of Malaya to develop a creative learning platform for children.',
      image: 'https://images.unsplash.com/photo-1616484999303-8e1f2b2b2b1f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80',
      location: 'Cheras',
      tel: '012-201 0284',
      address: '39A, Jalan Dato Haji Harun, Taman Taynton View, 56000 Cheras, Wilayah Persekutuan Kuala Lumpur',
    },
    {
      id: 3,
      title: 'PTGI',
      description: 'PTGI is a non-profit organization that aims to provide a platform for children to learn and develop their creative skills. We believe that every child is born with a creative mind and we want to help them unleash their creativity. We are currently working with the University of Malaya to develop a creative learning platform for children.',
      image: 'https://images.unsplash.com/photo-1616484999303-8e1f2b2b2b1f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80',
      location: 'Setapak',
      tel: '019-231 3105',
      address: '17A, Jalan 6/23e, Taman Danau Kota, 53300 Kuala Lumpur, Wilayah Persekutuan Kuala Lumpur',
    },
    {
      id: 4,
      title: 'Jen Music School',
      description: 'Jen Music School is a non-profit organization that aims to provide a platform for children to learn and develop their creative skills. We believe that every child is born with a creative mind and we want to help them unleash their creativity. We are currently working with the University of Malaya to develop a creative learning platform for children.',
      image: 'https://images.unsplash.com/photo-1616484999303-8e1f2b2b2b1f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80',
      location: 'Sungai Buloh',
      tel: '012-212 6281',
      address: 'Sungai Buloh, 9A, Jalan BRP 6/12, Bukit Rahman Putra, 40160 Shah Alam, Selangor',
    },
    {
      id: 5,
      title: 'STEM4ALL MAKERSPACE',
      description: 'STEM4ALL MAKERSPACE is a non-profit organization that aims to provide a platform for children to learn and develop their creative skills. We believe that every child is born with a creative mind and we want to help them unleash their creativity. We are currently working with the University of Malaya to develop a creative learning platform for children.',
      image: 'https://images.unsplash.com/photo-1616484999303-8e1f2b2b2b1f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80',
      location: 'Subang',
      tel: '011-1662 1661',
      address: 'L3-01, Da Men Mall, Persiaran Kewajipan, Usj 1, 47600 Subang Jaya, Selangor',
    },
    {
      id: 6,
      title: 'Tech Dome',
      description: 'Tech Dome is a non-profit organization that aims to provide a platform for children to learn and develop their creative skills. We believe that every child is born with a creative mind and we want to help them unleash their creativity. We are currently working with the University of Malaya to develop a creative learning platform for children.',
      image: 'https://images.unsplash.com/photo-1616484999303-8e1f2b2b2b1f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80',
      location: 'Penang island',
      tel: '011-5685 3895',
      address: 'Geodesic Dome, KOMTAR, Jln Penang, 10000 George Town, Pulau Pinang',
    },
    {
      id: 7,
      title: 'INFINITY8',
      description: 'INFINITY8 is a non-profit organization that aims to provide a platform for children to learn and develop their creative skills. We believe that every child is born with a creative mind and we want to help them unleash their creativity. We are currently working with the University of Malaya to develop a creative learning platform for children.',
      image: 'https://images.unsplash.com/photo-1616484999303-8e1f2b2b2b1f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80',
      location: 'Johor Bahru',
      tel: '018-220 4138',
      address: '37A, Jalan Harmonium 23/13, Taman Desa Tebrau, 81100 Johor Bahru, Johor',
    },
  ]

  const links = [
    { name: 'Open roles', href: '#' },
    { name: 'Internship program', href: '#' },
    { name: 'Our values', href: '#' },
    { name: 'Meet our leadership', href: '#' },
  ]
  const stats = [
    { name: 'Offices worldwide', value: '12' },
    { name: 'Full-time colleagues', value: '300+' },
    { name: 'Hours per week', value: '40' },
    { name: 'Paid time off', value: 'Unlimited' },
  ]




  return (
    <>
      <div className="bg-white py-5 ">
        <div className="mx-auto max-w-9xl  px-6  lg:px-8">
          <div className="mx-auto max-w-6xl lg:text-center">

            <p className="mt-6 text-2xl leading-8 text-gray-800">
              We are always looking for new partners to help us achieve our
              mission. If you are interested in partnering with us, please
              contact us at{' '}
              <a
                href='mailto:admin@eduwel.asia'
                className='text-blue-500'
              >
                admin@eduwel.asia
              </a>
            </p>
          </div>

          <div className="mx-auto mt-6 max-w-6xl  sm:mt-20 lg:mt-4 lg:max-w-6xl">
            <div className="flex  justify-center ">
              <h1 className="text-3xl py-10 mx-30  font-extrabold tracking-tight text-gray-900 sm:text-4xl">Center Partners</h1>
            </div>


            <dl className="grid max-w-xl grid-cols-1 gap-y-10 gap-x-8 lg:max-w-none lg:grid-cols-3 lg:gap-y-16">
              {PartnershipsData.map((feature) => (
                <div key={feature.title} className="relative pl-16 p-4 border border-gray-200 rounded-lg hover:border-gray-300 hover:shadow-lg">
                  <dt className="text-base font-semibold leading-7 text-gray-900">

                    {feature.title}
                  </dt>

                  <div className="mt-2 text-lg flex flex-row gap-3 leading-7 text-gray-600 ">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                    </svg> <dd className="text-base leading-7 text-gray-600">{feature.tel}</dd>
                  </div>

                  <div className="mt-2 text-lg flex flex-row gap-3 leading-7 text-gray-600 ">
                    <div className="mt-2 text-lg flex flex-row gap-3 leading-7 text-gray-600 ">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                      </svg>

                    </div>
                    <dd className="text-base leading-7 text-gray-600">{feature.address}</dd>
                  </div>



                </div>
              ))}
            </dl>
          </div>

          <div className="mx-auto max-w-2xl sm:mt-20 lg:max-w-4xl ">
            <div className="flex  justify-center ">
              <h1 className="text-3xl py-10 mx-30  font-extrabold tracking-tight text-gray-900 sm:text-4xl">Collaborative Partners</h1>
            </div>


            <dl className="grid max-w-xl grid-cols-1 gap-y-10 gap-x-8 lg:max-w-none lg:grid-cols-3 lg:gap-y-16">
              <div className="flex p-4 relative border justify-center items-center border-gray-200 rounded-lg hover:border-gray-300 hover:shadow-lg">
                <div className="flex justify-center items-center flex-row gap-3 ">
                  <div className="flex flex-row  gap-3">
                    <img src="https://study.um.edu.my/images/img-logo-UM.png" alt="UM" className="w-25 h-10" />
                  </div>
                  <dt className="text-base font-semibold leading-7 text-gray-900">
                    <a href="https://www.um.edu.my/" className="text-blue-500">

                    Universiti Malaya
                    </a>
                  </dt>


                </div>
              </div>

              <div className="relative p-4 border border-gray-200 rounded-lg hover:border-gray-300 hover:shadow-lg">
                <div className="flex justify-center mt-2 items-center flex-row gap-3 ">
                  <div className="flex flex-row  gap-3">
                    <img src={UMSLogo} alt="UM" className="w-20 h-10" />
                  </div>
                  <dt className="text-base font-semibold leading-7 text-gray-900">
                    <a href="https://stem.um.edu.my/" className="text-blue-500">

                    UM Stem 
                    </a>
                  </dt>


                </div>

              </div>

              <div className="relative p-4 border border-gray-200 rounded-lg hover:border-gray-300 hover:shadow-lg">
              <div className="flex justify-start mt-2 items-center flex-row gap-3 ">
                  <div className="flex flex-row  gap-3">
                    <img src="https://iad.kku.ac.th/upload/photo/web/TsBk8PHe.png" alt="UM" className="w-20 h-10 object-fill" />
                  </div>
                  <dt className="text-base font-semibold leading-7 text-gray-900">
                    <a href="http://www.recsam.edu.my/" className="text-blue-500">

                  SEAMEO RECSAM
                    </a>
                  </dt>


                </div>

              </div>

            </dl>
          </div>

          <div className="mx-auto max-w-2xl lg:max-w-4xl ">
            <div className="flex  justify-center ">
              <h1 className="text-3xl py-10 mx-30  font-extrabold tracking-tight text-gray-900 sm:text-4xl">Strategy Partners</h1>
            </div>


            <dl className="grid max-w-xl grid-cols-1 gap-y-10 gap-x-8 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">

              <div className="relative pl-16 p-4 border border-gray-200 rounded-lg hover:border-gray-300 hover:shadow-lg">
              <div className="flex justify-start items-center flex-row gap-3 ">
                  <div className="flex flex-row  gap-3">
                    <img src={PlayBookxLogo} alt="UM" className="w-30 h-10 object-fill" />
                  </div>
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  <a href="https://www.playbookx.com/blog/" target="_blank" rel="noreferrer">

                  Playbookx
                  </a>
                </dt>
              </div>



              </div>

              <div className="relative pl-36 p-4 border border-gray-200 rounded-lg hover:border-gray-300 hover:shadow-lg">
              <div className="flex justify-start items-center flex-row gap-3 ">
                  <div className="flex flex-row  gap-3">
                    <img src="https://www.eduplay.com.my/wp-content/uploads/2022/06/Eduplaylogo_horizontal__useonLightbackground.png" alt="UM" className="w-30 h-10 object-fill" />
                  </div>
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  <a href="https://www.eduplay.com.my/" target="_blank" rel="noreferrer">

                  EduPlay
                  </a>
                </dt>
              </div>


              </div>

            </dl>
          </div>


        </div>
      </div>


    </>
  )
}

export default Partnerships