import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import EventsData from '../data/EventsData.json'


const Events = () => {

    const [events, setEvents] = useState(EventsData)
    const [shorternedDescription, setShorternedDescription] = useState('')

    const [loading, setLoading] = useState(false)

    const FetchEvents = async () => {
        setLoading(true)
        const res = await fetch('src/data/EventsData.json')
        const data = await res.json()
        setEvents(data)
        setLoading(false)
    }

    const ReadMore = (description) => {
        if (description.length > 180) {
            return description.substring(0, 100) + ' Read More...'
        } else {
            return description
        }
    }

   
    function EventsSection() {
        return (
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 ">
                {events.map((exam) => (
                    <div key={exam.id} className="flex flex-col w-full h-64 border border-gray-200 rounded-md shadow-md sm:px-2 hover:shadow-lg hover:bg-gray-100">
                        <div className="flex flex-col justify-start p-2 w-full ">
                            <div className="flex flex-row items-start ">
                                <img src={exam.image} alt="Picture" className="bg-gray-200 w-24 h-24 rounded-md object-cover" />
                                <div className="flex flex-col flex-grow ml-4 ">
                                    <div className="text-md font-bold ">{exam.title}</div>
                                    <div className="text-sm text-gray-500">
                                        {exam.organiser}
                                    </div>
                                    <div className="text-sm text-gray-500">
                                        {exam.location}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col flex-grow gap-0">
                            <div className='flex flex-row'>
                                <div className='text-md text-gray-500 ml-2'>
                                    {ReadMore(exam.description)}
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-row justify-between  h-12 p-2 mx-2 items-center">
                            <div className="flex flex-row gap-2">
                                <div className="text-sm text-gray-500">
                                    <span className="bg-blue-200 text-blue-800 rounded-full px-2 py-1 text-xs font-semibold mr-2">
                                        {exam.type}
                                    </span>
                                    <span className="bg-teal-200 text-teal-800 rounded-full px-2 py-1 text-xs font-semibold mr-2">
                                        {exam.category}
                                    </span>
                                </div>
                            </div>
                            <div className="flex flex-row gap-2">


                                <button className="flex flex-row gap-2 items-center justify-center px-4 py-2 text-sm font-medium text-black border rounded-md  focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                                    {/* <EyeOutlined /> */}
                                    <Link to={`/events/${exam.id}`}>
                                        <span className="text-sm text-black font-medium">View</span>
                                    </Link>
                                </button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        )
    }




    return (
        <>
       
        <div className='flex flex-col items-center justify-center p-4 m-4'>
            <EventsSection />
        </div>
        </>

    )
}

export default Events