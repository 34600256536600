import React from 'react'
import { Link } from 'react-router-dom'



const Footer = () => {
  return (
    <footer className="relative bg-indigo-100 pt-8 pb-6 w-full">
      <div
        className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 "
        style={{ height: "80px" }}
      >
        <svg
          className="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            className="text-indigo-100 fill-current "
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
      <div className="container px-4 min-w-full">
        <div className="flex flex-wrap">
          <div className="w-full lg:w-7/12 px-4">
            <h4 className="text-3xl font-semibold ">
              Let's keep in touch!
            </h4>
            <h5 className="text-lg mt-0 mb-2 text-gray-700  md:text-xl lg:text-2xl xl:text-2xl 2xl:text-3xl">
              Find us on any of these platforms, we respond 1-2 business days.
            </h5>
            <div className="mt-6">
              <button
                className="bg-white text-blue-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2 p-3"
                type="button"
                onClick={() => { window.location.href = "https://www.facebook.com/codekidzmalaysia" }}
              >
                <img src="https://img.icons8.com/color/48/000000/facebook-new.png" />
              </button>
              <button
                className="bg-white text-blue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2 p-3"
                type="button"
                onClick={() => { window.location.href = "https://www.instagram.com/codekidz.asia/" }}
              >
                <img src="https://img.icons8.com/color/48/000000/instagram-new.png" />
              </button>
              
            </div>
          </div>
          
        </div>
        <hr className="my-6 border-gray-400" />
        <div className="flex flex-wrap items-center md:justify-between justify-center">
          <div className="w-full md:w-4/12 px-4 mx-auto text-center">
            <div className="text-sm text-gray-600 font-semibold py-1">
              Copyright © {new Date().getFullYear()}{" "}CodeKidz By{" "}
              <a
                href="https://www.facebook.com/marketingmavericksofficial"
                className="text-gray-600 hover:text-gray-900"
                target="_blank"
              >
                Marketing Mavericks
              </a>.
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer