import React from "react";
import HeroImg from "../Assets/hero.svg";
import Hero1 from "../Assets/Hero7.png";

const Hero = () => {
  return (
    <div className="flex flex-col items-center  justify-start min-h-[80vh]  bg-gradient-to-r from-orange-50 to-cyan-50 ">
      <div className="grid max-w-screen-full px-8 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
        <div className="mr-auto place-self-center lg:col-span-5 w-full lg:w-11/12">
          <h1 className="text-7xl font-extrabold leading-tight  tracking-tight text-gray-900  md:text-7xl lg:text-7xl">
            <span className="block text-red-400   font-Itim">
              Expert Tutor{" "}

            </span>
            <span className="block text-yellow-400 font-Itim ">
              for your child
            </span>
          </h1>
          <p className="mt-6 text-base  text-justify sm:mt-6 sm:text-base sm:max-w-full sm:mx-auto md:mt-5 md:text-xl lg:mx-0 text-gradient-to-r from-red-500 to-orange-500  hover:from-pink-500 hover:to-yellow-500">
            Give Your Child a Head Start in the Digital Age. Our coding classes
            are designed to give your child the skills and knowledge they need
            to succeed in the digital age. From basic coding concepts to
            advanced programming techniques, we cover it all. Get started today!
          </p>
          <div className="mt-8 sm:mt-8 sm:flex  lg:justify-start mb-8 ">
            <div className="rounded-md shadow">
              <a
                href="https://wa.link/xxm4x6"
                className="flex items-center justify-center px-8 py-3 text-base font-medium text-white  border border-transparent rounded-md hover:bg-indigo-700 md:py-4 md:text-lg md:px-10 bg-gradient-to-r from-red-500 to-orange-500  hover:from-pink-500 hover:to-yellow-500"
                target="_blank"
              >
                Request for Trial Class
              </a>
            </div>
          </div>
        </div>
        {/* <div className="flex justify-start lg:col-span-1 ">
          </div> */}
        <div className="flex justify-start lg:col-span-7 ">
          {/* <img className='w-full h-full ' 
                    src={Hero1} alt='Hero Image' /> */}

          <video
            className="bg-gray-200  w-[90vw] md:h-[60vh] object-cover rounded-md border-solid border-2 border-slate-800"
            controls
            src="https://firebasestorage.googleapis.com/v0/b/codekidsmy.appspot.com/o/Codekidz%20intro4.mp4?alt=media&token=bf8d7dba-06b1-4313-8f02-a8f349ab60b3&_gl=1*1nn5atq*_ga*MTU3NTcxMjYyNS4xNjkxOTk3NDgy*_ga_CW55HF8NVT*MTY5NjkxNjk1NC41Mi4xLjE2OTY5MTc3MzQuNTkuMC4w"
            type="video/mp4"
            autoPlay
            muted
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
